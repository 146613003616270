@charset "utf-8";

/* CSS Document */
html, body {height:auto;}
.small_block{width:16.66%; padding: 0 5px 0 10px;}
.dataTables_length{ display:none;}
.blue_bg{ background-color: #3c8dbc !important;}
.table_header{top: 6px;}
.filter_block{ width:22%; float: left;margin: 0 10px 0 0;}
.submit_button{width: auto; margin: 0;}
/*.table_hd{ position:static !important;}*/
.data_block{ height:auto;}
.navbar-custom-menu{    margin-left: auto;}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu{ width:200px !important;}
.pull-right{text-align: left;display: block;width:100%;}
.pull-right a i{ margin:0 0 0 0;}
.radio_button{position:relative; display: inline; margin-top: 10px; margin-bottom:10px; margin:0 30px 0 0;}
.radio_button label{ font-weight:normal;}
.radio_button input[type=radio]{margin: 0; margin-top:0; line-height: normal; vertical-align: middle;}
.radio_btn{ margin:0 10px 0 0;}
.topfilter_block{ margin-bottom:0;line-height: 46px;}
.input_block{ margin-bottom:0;margin: 6px 0 0 0;}
.submit_nw_btn{/*padding: 6px 19px;*/margin: 0 4px 0 0; padding:6px 22px;}
.select_data{text-align: center;border-top: none;padding-top: 0;}
.register_block{ position: relative; margin: 11px 0 0 0;text-align: center;}
.or_text{ font-size: 18px;position: absolute;left: 46%;top: -28%; background: #ececec;border-radius: 100%;padding: 7px 10px;}
.register_button{ margin:20px 0 10px 0;}
.data_search{ margin-bottom:30px;}
.pull-right-data{text-align: left;float: right;}
.customer_data{padding-left: 0;margin-bottom: 0;}
.customer_data>.list-group-item{position: relative;display: block;padding: 6px 15px;margin-bottom: -1px;background-color: #fff;border: 1px solid #ddd; border-left: 1px solid #ddd !important; border-right:1px solid #ddd !important;  padding-left:7px !important; padding-right:7px !important;}
.data_block{ padding:0 !important;}
.checkbox>label{padding-left:0 !important;}
.checkbox>label>div.icheckbox_flat-green{ margin-right: 7px;}
.garage_blockright{display: inline-block; float: right;}
.garage_block{ display:inline-block;}
.call_icon{ font-size:19px !important;position: absolute; right: 30px; top: 6px; color:#00a65a !important; cursor:pointer;}
.dashboard_table{ padding:15px!important;}
.filter_blocknew {width:29%;float: left;margin: 0 10px 0 0;}
.button_sec{ width: 10%;display: inline-block;text-align: center; margin: 10px 0;}
.submit_block{text-align: center;}
.floating_block{position:fixed; width:22%;}
.customer_viewsearch{float: right;width: auto; margin: 10px 0;}
.filter_txt{text-align: left;padding: 0 8px 0 0;font-weight: 600;}
.data_mode{display: block;}
.has-feedback > input{border-left:0;border-right:0;border-top:0;}
.frm_grp{padding:15px;}
.btn-flat{background:#429aff; border:1px solid;}
.btn-flat:hover{background:#1bbc9b; border:1px solid #12a486;}
.sign_in{width:120px; text-align: center;display: inline-block; border-radius: 4px !important;}
.sign_block{width:100%; text-align:center; font-size:13px; display:block; padding:5px 0;}
.forgot_link{ color:#909090;}
.form-control:focus{ border-color:#bddcff;}
.garage{width:100% !important;}
.navbar-static-top{background: #7200c9;}
.skin-blue .main-header .navbar{background:#3a006e !important;}
/*.login-page{ background:url(../images/insurance_img.png) repeat;}*/
.card .card-block { padding:0;}
.m-b-20{margin-bottom:20px; font-size:16px;}
h6 {font-size:14px;}
.text-right { text-align:right!important; position:relative;}
.card .card-block p { width: 100%;margin: 0; font-size: 12px;line-height: normal;font-weight: 500; padding: 3px 0 3px 12px;text-align: left;}
.bg-c-blue { background: linear-gradient(45deg,#4099ff,#73b4ff);    /*opacity: 0.3;*/}
.card {border-radius: 5px; -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16); box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16); border: none; margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out;}
.card {position: relative;display: -ms-flexbox; display: flex; -ms-flex-direction: column; flex-direction: column; min-width: 0; word-wrap: break-word; background-color: #fff;
    background-clip: border-box; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem; height: auto; /*width: 270px;*/}
.bg-c-green { background: linear-gradient(45deg,#2ed8b6,#59e0c5);   /* opacity: 0.3;*/}
.bg-c-purple { background:linear-gradient(45deg,#ad86df,#c699ff);   /* opacity: 0.3;*/}
.bg-c-green1 { background:linear-gradient(45deg,#00acc1,#59d3e2);   /* opacity: 0.3;*/}
.bg-c-yellow { background: linear-gradient(45deg,#FFB64D,#ffcb80);    /*opacity: 0.3;*/}
.bg-c-pink { background: linear-gradient(45deg,#FF5370,#ff869a);   /* opacity: 0.3;*/}
.skin-blue .main-header .logo{ padding:0;}
.main-header .logo{ font-size:18px;}
.order-card {color: #fff; }
.order-card i {font-size: 26px;}
.skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side{ background-color:#d2d6de !important;}
.skin-blue .sidebar a {color:#449cff;}
.skin-blue .sidebar-menu>li:hover>a, .skin-blue .sidebar-menu>li.active>a, .skin-blue .sidebar-menu>li.menu-open>a { color: #fff; background:#3a006e !important;}
.skin-blue .sidebar-menu>li.active>a {border-left-color: #3a006e !important;}
.skin-blue .main-header .logo{ background:#3c006b !important;}
.skin-blue .main-header .navbar .sidebar-toggle:hover{background:#270348 !important;}
.card:hover { -webkit-box-shadow: 0 0 25px -5px #9e9c9e; box-shadow: 0 0 25px -5px #9e9c9e;}
.main-header .logo{ font-size:18px !important;}
.skin-blue .main-header .navbar .nav>li>a:hover, .skin-blue .main-header .navbar .nav>li>a:active, .skin-blue .main-header .navbar .nav>li>a:focus, .skin-blue .main-header .navbar .nav .open>a, .skin-blue .main-header .navbar .nav .open>a:hover, .skin-blue .main-header .navbar .nav .open>a:focus, .skin-blue .main-header .navbar .nav>.active>a:hover
{    background: rgba(0,0,0,0.1);}
.text-right i{position: absolute; left:-13px; font-size:97px !important; top: -12px; transition: all .3s;}
.text-right span{font-size: 34px;font-weight: bold;}
.card-block > h2{margin: 17px 0 7px 0 !important;}
.box.box-primary {border-top-color:#e75d01 !important;}
.order-card a{ color:#fff !important;}
.box:hover{    box-shadow: 0 0 25px -5px #9e9c9e;}
.table-responsive-video{    min-height: .01%; overflow-x: auto; width:40%; display: table-cell; vertical-align: top;}
.video_container{border:1px solid #ddd; display:table-cell; width:60%; height:195px;}
.video_block{ display:table; width:100%;}
.video_container img{ width:100%;}
.changes_block{text-align: right; padding:5px 9px 20px 0; margin: 0;}
.video_container video{ width:100%; height:240px;}
.data_mode textarea{ width:100%; border-color:#d2d6de;}
.modal_container{ width:900px !important;}
.fr{ float:right;}
.daterangepicker td.active, .daterangepicker td.active:hover{ background:#3a006e !important;}
.modal_small{width:35% !important;}
ul.tabs{margin: 0px;padding: 0px;list-style: none;}
ul.tabs li{background: none;color: #222;display: inline-block;padding: 10px 15px;cursor: pointer;}
ul.tabs li.current{background:#fafafa; color: #222; border-top: 3px solid #3c8dbc; border-left: 1px solid #ddd;border-right: 1px solid #ddd; margin:0;}
.tab-content{display: none;background:#fafafa;padding: 15px;  border-bottom: 1px solid #ddd; border-left: 1px solid #ddd; border-right: 1px solid #ddd;border-top: 1px solid #ddd;}
.tab-content.current{display: inherit;}
.radiio_margin{ margin:0 !important;}
.white_box{ background:#ffffff; margin-bottom:0;}
.direct-chat-messages{height:auto;}
.chat_box{height:auto !important;}
.child-Count {display: inline;position: absolute;margin-top: -12px;background: #f16060;border-radius: 100%;width: 20px;text-align: center;left: 78px;top: 12px;color: #fff;font-size: 14px;height: 20px;}
.exists_policy{ color: #337ab7; font-size: 16px;  font-weight: bold;}
table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td{position:relative;}
.navcenter{text-align:center;}
.nav_head{padding: 12px 0px 0px 0px;display: inline-block;font-size: 19px;color: #fff;margin-right: 30px;}
#example1_wrapper .row .col-sm-12{ overflow-x:auto; padding:0 10px;}
.comments {width: 50% !important;}
.activebox{opacity:1;}
.order-card:hover i{transform:scale(1.2);}
.notification-card { text-align: center; color: #fff;}
.notification-card .card-block > div { margin: 15px 0px 0px 0px;}
.notification-card .card-block .notify-icon i {/* font-size: 30px;*/ padding: 0; margin: 0;}
.notification-card .card-block .notify-cont { padding: 15px 0; border-left: 1px solid #fff;}
.notification-card .card-block .notify-cont p { margin-bottom: 0;}
.notification-card .card-block .notify-icon i { font-size: 48px;}
.notification-card .card-block .notify-cont { padding: 0; border-left: none;}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{background-color: #e75d01; border-color: #ce5200;}
.box{    border-top: 3px solid #e75d01 !important;}
.sign_in {background: #429aff; border: 1px solid #367fa9; margin: 5px;}
.sign_in:hover { background: #00a3cb;}
.table-bordered>thead>tr>th, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>tbody>tr>td, .table-bordered>tfoot>tr>td{    border: 1px solid #ddd !important;}
.btn-primary { background-color: #e75d01; border-color: #e48c52;}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover{ background-color: #e75d01; border-color: #e48c52;}
.btn-primary:hover, .btn-primary:active, .btn-primary.hover{background-color: #e75d01;}
.btn-primary.focus, .btn-primary:focus{background-color: #e75d01; border-color: #e75d01;}
.btn-primary:hover{border-color: #e75d01;}
.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover{background-color: #e75d01; border-color: #e75d01;}
.upload-report{padding:0;}
.upload-report .up-form{padding:0;}
.submit-bottom{margin:10px 0;}
.submit-bottom .btn{padding:6px 26px;}
.insurer_filter_block{ width: 22%; float: left; margin: 0 10px 0 0;}
@media (min-width: 992px){.submit-bottom .col-md-offset-10{margin-left: 88%;}}

.video_block .table_header{
   top:0;
}


.row{    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;}
.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
    cursor:pointer;
}

.thumb_block{display: table;width: 100%;padding: 10px;margin: 0;box-sizing: border-box;}
.thumb_block img{display: inline-block;margin: 0 5px 0 0; width: 50px; height: 50px;}

.small_block{    width: 16.66%;}
.notification-card .card-block .notify-cont h4{    font-size: 34px;font-weight: 600;}
.commnt_heading{margin-bottom:0px;}
/* @media (min-width: 1200px)
style.css:1
.col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
} */
@media screen and (max-width:1024px) {
.modal_container{ width:80% !important;}
.small_block {width: 50%;}
.insurer_filter_block{ width:29%;}
div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate{ text-align:right;}
}

@media screen and (max-width:469px) {
	.small_block {width:100%;}
	.insurer_filter_block {width: 50%;float: initial;margin: 0 10px 0 0;display: inline;}
	.submit_button { width: auto; margin: 13px 0 0 0; float: right;}
	.login-box, .register-box{width: 340px;}
	.video_block{ display:block;}
	.table-responsive-video{ width:100%; display:table;}
}
/*theme mode default */
.upload_widget .form-group {
margin:10px 0;
}
.radio1 label{
    margin-left: 10px;
    margin-right:0;
}
.form-details .row{
    margin:0 0 15px 0;
}
.form-details input[type=radio]{
margin:0 0px;
vertical-align:middle;
}
.form-details input[type=radio]:nth-child(2){
    margin-left:20px;
}
.form-details .btn{
    padding: 6px 25px;
}
.btn_ctrl .col-md-6 {
width:48%;
}
.btn_ctrl .col-md-6:first-child  {
margin-right:10px;
}
.plus_icon {
float:right;
margin:0 5px;
cursor:pointer;
}
.field1 label {
margin-right:30px;
}
.field1 input {
padding:15px 10px;
}
.attach {
float:right;
width:174px;
}
.up-form label,.report_download{
    margin:5px;
}
.fix_btn{
    width:120px;
    float:right;
}
.attach .btn {
    width:100%;
}
.capture_btn{text-align:center;}
.capture_btn select{padding: 6px;line-height: 1.42857143;position: relative;top: 2px;border-radius: 4px;}
  @-moz-document url-prefix() {
.field1 input {
padding:15px 18px;
}
}
.navbar-nav>.user-menu>.dropdown-menu>user-footer_alert{background-color: #4e4e4e;
    padding: 10px;}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i {
    width:300px !important;
    padding: 10px;
    max-height: 286px;
    overflow: auto;
    border: 1px solid #bacfe8;
}
.navbar-custom-menu>.navbar-nav>li.dropdown.user.user-menu.notify_count{position:relative;}
.navbar-custom-menu>.navbar-nav>li.dropdown.user.user-menu.notify_count span.notification_count{    padding: 0;
    background:#ff0000;
    color: #ffffff;
    right: 8px;
    position: absolute;
    top: 9px;
    font-size: 10px;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    line-height: 16px;
    text-align: center;
    font-weight: bold;
    z-index: 9999;}
.card-eight .small_block {
  /* width: 9%; */
  padding: 0 0px 0 8px;}
.card-eight .small_block .notification-card {height: 110px;}
.card-re h4{
        width: 100%;
    font-size: 38px;
    text-align: left;
      margin: 0 10px;
      font-weight:900;
}
.card-re .notify-icon .fa {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0,0,0,0.15);
}
.content-wrapper .table_hd{
     position: relative !important;
    width: 83%;
    top: 4px;
    z-index:112;
}
.table_hd h3{
    float:left;
}
.table_hd a{
    float:right;
}

.float-right {
  float:right !important;
}
.dataTables_wrapper .row:nth-child(1){
    position: absolute;
    width: 100%;
    top: 11px;
}
/*end theme mode default */


.navbar-nav>.user-menu>.dropdown-menu>user-footer_alert{background-color: #4e4e4e;
    padding: 10px;}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i {
    width:300px !important;
    padding: 10px;
    max-height: 286px;
    overflow: auto;
    border: 1px solid #bacfe8;
}

.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i li.user-footer_head{/*border:1px solid #e4e4e4;*/
    display:table;width:100%;}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i li.user-footer_head label.h_label{width:25%;display:table-cell;padding:4px;vertical-align: middle;font-weight:normal;text-align:center;color:#3c8dbc}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i li.user-footer_head span.h_span{width:75%;display:table-cell;padding:4px;vertical-align: middle;font-weight:normal;text-align:left;color:#3c8dbc}

.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i li.user-footer_alert{/*border:1px solid #e4e4e4;*/
    display:table;width:100%;border-top:none}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i li.user-footer_alert:last-child{/*border:1px solid #e4e4e4;*/
    display:table;width:100%;/*border-bottom:1px solid #e4e4e4;*/}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i li.user-footer_alert label.h_label{width:25%;display:table-cell;padding:4px;vertical-align: middle;font-weight:normal;text-align:center;}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu.alert_i li.user-footer_alert span.h_span{width:75%;display:table-cell;padding:4px;vertical-align: middle;font-weight:normal;text-align:left;position:relative}

h3.assign_box-title{color:#444;text-align:left;border-radius:5px;font-size:24px;margin:0;display:inline-block;line-height:1;padding:0 10px 10px 15px}
.assign_right{text-align:center;margin:25px 0}
.padding-ctr{padding-left:0;padding-right:0;}
.assign_box-header.with-border{border-bottom:1px solid #f4f4f4}
.col-md-6.claimass,.col-md-6.claimass:first-child .col-md-5{padding:0}
#AssignClaims_wrapper{padding:0 5px}
.AssignClaims{table-layout:fixed;}
.AssignClaims .surveyor{word-break:break-all;}
.sign_block.text-left{text-align:left;}
.sign_block.text-left + .login_btn{margin-top:0;}
.zip-download{float: right; margin: 0 10px; font-size: 18px;color: black;}

/*loader  starts*/
.loader_wrap{
    position: fixed;
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    z-index: 100000;
    background: rgba(0,0, 0,0.2);
    }

    .loader_wrap .loader {
    border: 6px solid #fff;
    border-radius: 50%;
    border-top: 6px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    position: absolute;
    left:0;
    right: 0;
    margin: 0 auto;
    top:48%;
    transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    }

    /* Safari */
    @-webkit-keyframes spin {
    0% { -webkit-transform: translateY(-50%) rotate(0deg); }
    100% { -webkit-transform: translateY(-50%) rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
    }

    /*loader  ends*/

/*button loader  starts*/
.small_loader::before {
    content: "" !important;
    border: 2px solid #d6d4d4;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
}
/*button loader  ends*/

/* login page starts*/
.login-page{
  background: #d2d6de;
}

.login-box-body {
  background: #fff;
  border-top: 0;
  color: #666;
}
.login_heading{padding:15px 10px; margin:0; display:block;background:-webkit-gradient(linear,left top,right top,from(#4099ff),to(#73b4ff));background:linear-gradient(to right,#4099ff,#73b4ff);color:#fff; font-size:20px; border-radius:4px 4px 0 0; text-align:center; line-height:normal; }
.login_container{border-radius: 4px;padding: 0;-webkit-box-shadow:2px 0 2.94px 0.06px rgba(4,26,55,0.16);box-shadow:2px 0 2.94px 0.06px rgba(4,26,55,0.16);}

.login-box-body .form-control-feedback, .register-box-body .form-control-feedback{color:#72b4ff;}
.login-box, .register-box{width:400px; margin:10% auto;}
.login_btn{
  text-align:center;
  display: block !important;
}


/*login page ends*/

:host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
  color: rgb(0,0,0) !important;
  font-size: 14px;
  font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
  vertical-align: baseline;
  background-color: rgb(255, 255, 255) !important;
}

:host ::ng-deep .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
  color: rgb(0,0,0) !important;
  font-size: 14px;
  font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
}

:host ::ng-deep .p-paginator .p-paginator-current {
  font-size: 12px;
  font-family: 'Roboto';
}

:host ::ng-deep .p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
  font-size: 12px;
  font-family: 'Roboto';
}

:host ::ng-deep .p-datatable table{
  table-layout: inherit;
  margin: 0px;
}


:host ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-family: 'Roboto';
  font-size: 12px;
  color: rgba(0,0,0,.87);
}

:host ::ng-deep .p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0;
}

.mat-form-field{
  display: block !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex{
  background-color: white !important;
}
.mat-form-field-wrapper{
  padding-bottom: 0px !important;
}
.mat-form-field-appearance-fill .mat-form-field-flex{
  padding: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix{
  padding: 0px 0px 0px 0px;
}


.mat-icon-button{
  float: right !important;
  /* bottom: 28px !important; */
}

.mat-form-field-infix{
  height: 30px;
  border-top: 8px solid transparent !important;
}

.mat-form-field-underline{
  width: 0px !important;
  bottom: 0px !important;
}

.dateBorder{
  border: 1px solid #ccc;
}

.mat-date-range-input{
  margin-left: 10px;
}

.error-snack {
  background: red;
  color: white;
}
