.skin-blue .main-header .navbar {
	background: #29166f !important;
}
.skin-blue .main-header .logo {
	background: #29166f !important;
}
.skin-blue .sidebar-menu > li:hover > a, .skin-blue .sidebar-menu > li.active > a, .skin-blue .sidebar-menu > li.menu-open > a {
	color: #fff;
	background: #29166f !important;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
	background:#29166f !important;
}
.box.box-primary {
	border-top-color: #29166f !important;
}
.box {
	border-top: 3px solid #29166f !important;
}
.table_hd {
	position: static !important;
}
.logo-lg {
text-align:left;
}
.submit_button .submit_btn {
background-color: #29166f !important;
border-color: #29166f !important;
}
.btn-primary {
    background-color: #29166f;
    border-color: #29166f;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background-color: #29166f !important;
    border-color: #29166f !important;
}
.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover{    background-color: #595175;
    border-color: #7568a2;}
.login_heading {
background: #29166f;
}
.logo-mini img {
    width:50%;
}
.logo-lg img{
width:100%;
}
.sign_in {
    background: #29166f !important;
    border: 1px solid #29166f !important;
}
.sign_in:hover {
    background: #5236b7 !important;
}
.btn-primary:hover, .btn-primary:active, .btn-primary.hover {
    background-color: #5236b7;
    border-color: #5236b7;
}
    .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
       background-color: #5236b7;
    border-color: #5236b7;
    }
.btn-primary.focus, .btn-primary:focus {
    background-color: #5236b7;
    border-color: #5236b7;
}
.btn-bs-file{
    position:relative;
    width:100%;
}
.btn-bs-file input[type="file"]{
    position: absolute;
    top: -9999999;
    filter: alpha(opacity=0);
    opacity: 0;
    width:0;
    height:0;
    outline: none;
    cursor: inherit;
}

.text-small.txt_sm {
    margin: 0px 0 12px 0;
    display: block;
    color: #888;
    font-size: 13px;
}

.upload_widget .form-group {
margin:10px 0;
}

.btn_ctrl .col-md-6 {
width:48%;
}
.btn_ctrl .col-md-6:first-child  {
margin-right:10px;
}
.plus_icon {
float:right;
margin:0 5px;
cursor:pointer;
}
.field1 label {
margin-right:30px;
}
.field1 input {
padding:15px 10px;
}
.attach {
float:right;
width:174px;
}
    .attach .btn {
    width:100%;
    }
  @-moz-document url-prefix() {
.field1 input {
padding:15px 18px;
}
}